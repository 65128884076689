<template>
  <div class="a-slide__packages">
    <swiper
      ref="pckgSlider"
      :options="options"
    >
      <swiper-slide
        v-for="(item, i) in mainPackages"
        :key="'pk-' + i"
        class="packageBox"
        :class="{
          'selected': selectedClass(item),
          'included': includedClass(item),
          'toggle': dropdownActive === i || tooltip === 'package-' + item.id
        }">
        <div class="packageBox-inner" @click="$emit('handlePackages', item)">
          <img :src="baseUrl + item.image" alt="" />
          <span v-if="item.has_sub_options && state.tariff && triggerChange">
            <template v-for="(sub, index) in subPackages(item)">
              <span class="innerSubOption" v-if="sub.active" :key="`sub-o-i-${i}-${index}`" v-text="sub.name"></span>
            </template>
          </span>
        </div>
        <div class="packageBox-dropdown" v-if="item.has_sub_options && dropdownActive === i"
        :id="'dropdown-' + i" :ref="'dropdown-' + i" tabindex="-1" @blur="dropdownActive = null">
          <ul class="m-0 p-1" >
            <template v-for="(sub, index) in subPackages(item)">
              <!-- v-if="!state.tariff || (sub.tariffs && sub.tariffs.includes(state.tariff.id))" -->
              <li
              class="m-0"
              :class="{ active: sub.active }"
              :key="`sub-o-${i}-${index}`"
              @click.prevent="handleSubOptionEmit(sub, index)"
              v-text="sub.name"></li>
            </template>
          </ul>
          <!-- @click="handleSubOptionEmit(i, index)" -->
        </div>
        <div class="dropdown-placeholder" v-if="item.has_sub_options">
          <button class="btn icon" @click.stop="handleDropdown(i)">
            <span class="d-flex align-items-center justify-content-center" v-text="dropdownActive === i ? '-' : '+'"></span>
          </button>
        </div>
        <div class="tooltip-placeholder">
          <button class="btn icon" :class="'package-' + item.id" @click.stop="$emit('handleTooltip', 'package', item.id)" @blur="$emit('handleTooltip', null, null)">
            <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
          </button>
        </div>
        <div v-if="tooltip && tooltip === 'package-' + item.id" class="tooltip-holder" :class="item.tooltip_position" v-html="item.tooltip"></div>
      </swiper-slide>
      <div v-if="mainPackages.length > 14" class="swiper-pagination swiper-calc-pagination" slot="pagination"></div>
      <div v-if="mainPackages.length > 14" class="swiper-button-prev sbp" slot="button-prev"></div>
      <div v-if="mainPackages.length > 14" class="swiper-button-next sbn" slot="button-prev"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  props: {
    packages: {
      type: Array,
      default: null,
      required: true
    },
    state: {
      type: Object,
      default: null
    },
    baseUrl: {
      type: String
    },
    tooltip: {
      type: String,
      default: null
    },
    triggerChange: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      dropdownActive: null,
      options: {
        slidesPerView: 7,
        autoHeight: false,
        navigation: {
          nextEl: '.swiper-button-next.sbn',
          prevEl: '.swiper-button-prev.sbp'
        },
        slidesPerGroup: 6,
        slidesPerColumn: 2,
        slidesPerColumnFill: 'row',
        spaceBetween: 44,
        pagination: {
          el: '.swiper-pagination.swiper-calc-pagination'
        }
      }
    }
  },
  computed: {
    mainPackages () {
      return this.packages.filter(item => !item.is_sub_option)
    },
    swiper () {
      return this.$refs['pckgSlider']?.$swiper
    }
  },
  methods: {
    selectedClass (item) {
      return this.state &&
        (this.state.selectedPackages.includes(item.id) ||
        (this.state.addons.includes(item.id)) ||
        (this.state.tariff?.id && item?.tariffs?.includes(this.state.tariff.id) && this.state.tariffActivated) ||
        (this.state.package && this.state.package.id === item.id))
    },
    includedClass (item) {
      return this.state?.tariff?.id &&
      (
        (item.tariffs && item.tariffs.includes(this.state.tariff.id)) ||
        (this.state.package && this.state.package.id === item.parent_id) ||
        (item?.has_sub_options && this.packages?.filter(pck => pck.parent_id === item.id).some(sub => sub?.tariffs?.includes(this.state.tariff.id)))
      )
    },
    handleDropdown (i) {
      this.dropdownActive = this.dropdownActive !== i ? i : null
      if (this.dropdownActive !== null) {
        this.$nextTick(() => {
          document.querySelector('#dropdown-' + this.dropdownActive) && document.querySelector('#dropdown-' + this.dropdownActive).focus()
        })
      }
      // if (this.dropdownActive) this.$refs['dropdown-' + this.dropdownActive].focus()
    },
    handleSubOptionEmit (item, index) {
      this.dropdownActive = null
      this.$emit('handlePackages', item)
    },
    subPackages (item) {
      return this.packages.filter(sub => sub.parent_id === item.id)
    }
  },
  mounted () {
    setTimeout(() => {
      this.swiper.slideNext()
      this.swiper.slidePrev()
      this.swiper.update()
    }, 500)
  }
}
</script>
